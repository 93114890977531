import { getOffers } from '../../../services/WebServicesAPI';
import {
  Term,
  OfferById,
  OffersById,
  OfferFromService,
  ResponseOfferTypes,
  MiscOfferFromService,
  LeaseAndAprOfferFromService,
} from '../../../models/PEMS/PEMOffers';
import {
  InitObj,
  BuyOfferTab,
  LeaseOfferTab,
} from '../../../models/VIEW/ViewOffersTab';
import {
  defaultBuyOffer,
  defaultLeaseOffer,
  convertTierNumber,
  getCreditScoreItem,
  getOffersByTierAndTerm,
} from '../Utils/offersFactoryUtils';
import { setOffersBuyTab, setOffersLeaseTab } from '../Factories/offersFactory';
import { CreditScore } from '../../../constants/CreditScoreConstants';

export const getOffersForSeriesYear = async ({
  models,
  region,
  state,
  tdaCode,
  series,
  year,
}: {
  series: string; // series code
  year: string; // year code
  models: Array<{ code: string }>;
  region: string;
  state: string;
  tdaCode: string;
  offerId?: string;
  customMsrpParam?: string;
}) => {
  const response = await getOffers({
    region,
    state,
    tdaCode,
    series,
    year,
  });

  if (response.data.aprOffers) {
    response.data.aprOffers.sort(sortTierTerms);
  }

  if (response.data.leaseOffers) {
    response.data.leaseOffers.sort(sortTierTerms);
    assignMainLeaseTierTerm(response.data.leaseOffers);
  }

  // here for all our models for our selected YearSeries, we want to map
  // all applicable offers
  const offersById: OffersById | any = models.map(model => ({
    data: {},
    model: model.code,
  }));

  // OAT MS Way of sending error :/
  offersById.forEach((item: OfferById) => {
    for (const i of Object.keys(response.data)) {
      if (Array.isArray(response.data[i])) {
        item.data[i] = (response.data[i] as OfferFromService[]).filter(offer =>
          offer.includedModels.includes(item.model)
        );
      }
    }
  });
  return offersById;
};

const sortTierTerms = (offer1: any, offer2: any) => {
  const tier1 = offer1.tier === '1+' ? 0 : Number(offer1.tier);
  const tier2 = offer2.tier === '1+' ? 0 : Number(offer2.tier);
  return tier1 < tier2
    ? -1
    : tier1 > tier2
    ? 1
    : offer1.term < offer2.term
    ? -1
    : offer1.term > offer2.term
    ? 1
    : 0;
};

const assignMainLeaseTierTerm = (
  leaseOffers: LeaseAndAprOfferFromService[]
) => {
  const mapById: { [idx: string]: LeaseAndAprOfferFromService[] } = {};
  // map by ID
  leaseOffers.forEach(offer => {
    if (!mapById[offer.id]) {
      mapById[offer.id] = [];
    }
    mapById[offer.id].push(offer);
  });

  // Assume each group will contain the same term but different tier levels. The main tier will be the first (usually tier 1+).
  for (const offerId of Object.keys(mapById)) {
    const configuredTier = mapById[offerId][0];
    mapById[offerId].forEach(offer => {
      offer.configuredTier = configuredTier;
      offer.isConfiguredTierTerm = false;
    });
  }
  return mapById;
};

// takes offers from API, and craetes internal offers items
export const processOffersForSeriesYear = ({
  trimParam,
  offersById,
  regionCode,
  initialOfferId,
  customMsrpParam,
}: {
  customMsrpParam?: string;
  initialOfferId?: string;
  regionCode: string;
  offersById: OffersById;
  trimParam?: string;
}) => {
  let preselectedOffer: InitObj | undefined;
  const lowestAdvertisedLease = findLowestAdvertisedLeaseOffer(offersById);
  const offers = offersById.map(item => {
    const processedItem = processOffersItem({
      lowestAdvertisedLease,
      customMsrpParam,
      initialOfferId,
      regionCode,
      modelCode: item.model,
      trimParam,
      offers: item.data,
    });
    if (!preselectedOffer && processedItem.preselectedOffer) {
      preselectedOffer = processedItem.preselectedOffer;
    }

    return {
      processedOffers: processedItem,
      model: item.model,
    };
  });

  return {
    offers,
    preselectedOffer,
  };
};

/**
 * Converts offers from service
 * and create buy/tab view offer structures
 */
const processOffersItem = ({
  offers,
  modelCode,
  trimParam,
  regionCode,
  initialOfferId,
  customMsrpParam,
  lowestAdvertisedLease,
}: {
  offers: OfferById['data'];
  modelCode: OfferById['model'];
  trimParam?: string;
  regionCode: string;
  initialOfferId?: string;
  customMsrpParam?: string;
  lowestAdvertisedLease?: LeaseAndAprOfferFromService;
}) => {
  let preselectedOffer: InitObj | undefined;
  const buyTab = defaultBuyOffer();
  const leaseTab = defaultLeaseOffer();
  if (offers) {
    const aprOffers = filterOffersByType(
      offers,
      modelCode,
      'aprOffers'
    ) as LeaseAndAprOfferFromService[];
    const leaseOffers = filterOffersByType(
      offers,
      modelCode,
      'leaseOffers'
    ) as LeaseAndAprOfferFromService[];
    const cashOffers = filterOffersByType(offers, modelCode, 'cashOffers');
    const miscOffers = filterOffersByType(
      offers,
      modelCode,
      'miscOffers'
    ) as MiscOfferFromService[];

    const leaseDefaults = offers.rcfDefault || undefined;
    const buyDefaults = offers.rateDefault || undefined;

    // use regional rcf for model
    if (leaseDefaults) {
      leaseTab.defaults = leaseDefaults;
    }
    if (buyDefaults) {
      buyTab.defaults = buyDefaults;
    }

    // If Lease has no advertised offers, add lowestAdvertisedLease if available to show in carousel
    const advertisedLeaseOffers = leaseOffers.filter(
      offer => offer.isAdvertised
    );

    if (!advertisedLeaseOffers.length && lowestAdvertisedLease) {
      const lowestLeaseCopy = Object.assign({}, { ...lowestAdvertisedLease }); // make a copy so we don't use the same object per model code
      lowestLeaseCopy.isLowestAdvertisedLease = true; // flag the copy as advertised lease
      leaseOffers.push(lowestLeaseCopy);
    }

    // Based on the offer type and value, find the offer to preselect
    const {
      initOfferObj: preselectedBuyOffer,
      tierTermsOffersMap,
      tierTermsNonAdMap,
      maxTerm,
    } = setOffersBuyTab({
      aprOffers,
      cashOffers,
      miscOffers,
      initialOfferId,
      tab: buyTab.__tab__,
      offerType: buyTab.__offerType__,
    });

    buyTab.tierTermsOffersMap = tierTermsOffersMap;
    buyTab.tierTermsNonAdMap = tierTermsNonAdMap;
    buyTab.terms = String(maxTerm); // @TODO leave as number is probably better

    const {
      initOfferObj: preselectedLeaseOffer,
      tierTermsOffersMap: tierTermsOffersMapLease,
      tierTermsNonAdMap: tierTermsNonAdMapLease,
      availableTerms,
      nonMatchingModelCodeOffer,
    } = setOffersLeaseTab({
      offerType: leaseTab.__offerType__,
      tab: leaseTab.__tab__,
      initialOfferId,
      leaseOffers,
      regionCode,
      cashOffers,
      miscOffers,
      modelCode,
    });
    leaseTab.tierTermsOffersMap = tierTermsOffersMapLease;
    leaseTab.tierTermsNonAdMap = tierTermsNonAdMapLease;
    leaseTab.availableTerms = availableTerms;
    leaseTab.nonMatchingModelCodeOffer = nonMatchingModelCodeOffer;

    preselectedOffer = preselectedBuyOffer || preselectedLeaseOffer;

    initializeOffersByTierTerm(preselectedBuyOffer, buyTab);
    initializeOffersByTierTerm(preselectedLeaseOffer, leaseTab);
  }

  return {
    buy: buyTab,
    lease: leaseTab,
    preselectedOffer,
  };
};

const initializeOffersByTierTerm = <T extends BuyOfferTab | LeaseOfferTab>(
  preselectedOffer: InitObj | undefined,
  tabObj: T
) => {
  let tier = convertTierNumber(tabObj.tier);
  let term = tabObj.terms;

  if (preselectedOffer) {
    tier = preselectedOffer.key.split(' ')[0];
    term = preselectedOffer.key.split(' ')[1] as Term;
  }
  tabObj.terms = term;
  tabObj.creditScore = getCreditScoreItem(tier) as CreditScore;
  tabObj.offers = getOffersByTierAndTerm({
    tier,
    term,
    tierTermsOffersMap: tabObj.tierTermsOffersMap,
  });
};

const filterOffersByType = (
  offers: OfferById['data'],
  modelCode: OfferById['model'],
  type: ResponseOfferTypes
) =>
  (offers[type] || []).filter(
    offer => offer.includedModels && offer.includedModels.includes(modelCode)
  );

const findLowestAdvertisedLeaseOffer = (offersById: OffersById) => {
  let winner: LeaseAndAprOfferFromService | undefined;
  for (const item of offersById) {
    if (!item.data.leaseOffers) {
      continue;
    }
    const filteredOffers = item.data.leaseOffers.filter(
      offer => offer.isAdvertised
    );
    // eslint-disable-next-line no-loop-func
    filteredOffers.forEach(offer => {
      // If first offer found, assign it and use the queried model code
      if (!winner || (!winner.isLeaseExample && offer.isLeaseExample)) {
        winner = offer;
      } else if (winner.isLeaseExample === offer.isLeaseExample) {
        // Assign offer and queried model number if following conditions meet:
        // targetPayment is lower or targetPayment equals, but the queried model code is lower
        // tslint:disable-next-line
        if (
          offer.targetPayment < winner.targetPayment ||
          (offer.targetPayment === winner.targetPayment &&
            offer.modelNumber < winner.modelNumber)
        ) {
          winner = offer;
        }
      }
    });
  }
  return winner;
};

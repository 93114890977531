import BrandService from './BrandService';

import * as BreakPointUtils from '../hooks/useBreakpoints';

import BreakpointStore from '../stores/BreakpointStore';
import EstimatorStore from '../stores/EstimatorPage';
import GlobalStore from '../stores/GlobalStore';
import ModalStore from '../stores/ModalStore';

const $document: Document = document;
const $timeout: typeof setTimeout = setTimeout;

let _source = window.parent !== window ? window.parent : undefined;
/*
  origin will be set on initialisation by parent.
  setting to * so height messages that can be sent before init
  is called by parent frame, giving a slightly better UX.
*/
let _origin = '*';

let _lastHeightSent = 0;

/* Initializes the message event listener to watch for post messages in global scope */
export const initialize = () => {
  const isInitialized = 'message' in window;

  if (!isInitialized) {
    window.addEventListener('message', postMessageHandler, false);
    window.onresize = () => {
      sendHeight();
    };
  }
};

function parseEventData(data: any) {
  let dataobj;
  try {
    dataobj = JSON.parse(data);
  } catch (error) {
    console.log(' •• PMH errer •• ', dataobj);
  }
  return dataobj;
}

/*
  Handles incoming post message requests and broadcasts to module
  We require that the init is called first from the parent source to setup
  the origin variables globally so we know the source to send to when select a
  new model/zipcode/vehicle/etc...
*/
function postMessageHandler(event: any) {
  const dataobj = parseEventData(event.data);
  if (dataobj === undefined) {
    return;
  }

  if (event.origin === '') return;

  const method = dataobj.method;
  switch (method) {
    case 'init': {
      init(event);
      break;
    }
    case 'getHeight': {
      sendHeight();
      break;
    }
    case 'zipchange': {
      // MESSAGE SENT FROM PARENT ZIP MODAL. see: tcom-global-header.js
      GlobalStore.zipChangeCB(dataobj);
      break;
    }
    case 'vehiclechange': {
      // MESSAGE SENT FROM PARENT NAV VHEICLE SELECT. see:
      EstimatorStore.vehicleChangeCB(dataobj.data);
      break;
    }
    case 'tabIn': {
      GlobalStore.globalTabInCB();
      break;
    }
    default: {
      break;
    }
  }
}

/*
    Callback function when parent source sends its info to the application.
    We set the origin variables in this service and set the onresize event listener (since we have the source variables now)
  */
function init(event: any) {
  const dataobj = parseEventData(event.data);
  // set brand
  BrandService.setBrand(dataobj.brand);
  // set breakpoints
  if (dataobj.breakpoints) {
    if (dataobj.breakpoints.tablet) {
      BreakpointStore.tablet = dataobj.breakpoints.tablet;
    }
    if (dataobj.breakpoints.desktop) {
      BreakpointStore.desktop = dataobj.breakpoints.desktop;
    }
  }

  // set parent source info
  _source = event.source;
  _origin = event.origin;
  sendHeight();
}

/* Gets height of document */
const getDocumentHeight = () => {
  // jshint unused:false
  const body = $document.body;
  const html = $document.documentElement;
  let height = Math.min(
    body.offsetHeight,
    html.scrollHeight,
    html.offsetHeight
  );

  if (
    ModalStore.displayOfferDetailsModal ||
    ModalStore.displayDisclaimerModal ||
    ModalStore.displayContactDealerModal ||
    ModalStore.displayPriceDetailsModal ||
    ModalStore.displayKbbTradeInModal
  ) {
    height = Math.max(body.offsetHeight, html.scrollHeight, html.offsetHeight);
  }

  // WFMB-345 changing between tabs (finance, lease) offsetHeight = 0
  if (height === 0) {
    height = Math.max(body.offsetHeight, html.scrollHeight, html.offsetHeight);
  }
  height = height < 200 ? 200 : height;

  // so if modal is open
  const modalOpen =
    ModalStore.displayConfirmOfferChangeModal ||
    ModalStore.displayConfirmGradeChangeModal ||
    ModalStore.displayDisclaimerModal ||
    ModalStore.displayOfferDetailsModal ||
    ModalStore.displayPriceDetailsModal ||
    ModalStore.displayKbbTradeInModal;
  if (modalOpen) {
    // how to get modal height when we have so many of them...

    // can't use modal-dialog incase parent sets an offset, which causes an infinite loop
    // var modalHeight = $('.modal-content').height();
    let modalHeight =
      document.querySelector('.modal-content')?.clientHeight ?? 0;

    // add margins if tablet/desktop mode
    // 2px comes from the top and bottom borders
    if (!BreakPointUtils.isMobile()) {
      modalHeight += 62;
    }
    if (modalHeight > height) {
      height = modalHeight;
    }
  }

  return height;
};

/* Sends the current state to parent source */
export const stateChange = (jsonparams: string) => {
  if (_source && _origin && _origin !== null) {
    const pipeparams = jsonparams.replace(/:/g, '!');
    const zStr = { stateChange: pipeparams };
    const jsonString = JSON.stringify(zStr);
    _source.postMessage(jsonString, _origin);
  }
};

// /* Sends zip to parent source */
/* isSingle tells parents not send zip we've just sent back down, preventing loop */
export const sendZip = (zip: string, isSingle: boolean) => {
  if (_source && _origin && _origin !== null) {
    const zStr = isSingle ? { setZipSingle: zip } : { setZip: zip };
    const jsonString = JSON.stringify(zStr);
    _source.postMessage(jsonString, _origin);
  }
};

/* Tells parent source to scroll to the top */
export const sendScrollTop = () => {
  if (_source && _origin && _origin !== null) {
    const jsonString = JSON.stringify({ scrollTop: '0' });
    _source.postMessage(jsonString, _origin);
  }
};

/* Sends height to parent source */
export const sendHeight = () => {
  if (window.location.origin === 'file://') {
    // force this for file serving ( stand alone )
    _origin = '*';
  }

  const height = getDocumentHeight();
  if (height === _lastHeightSent) {
    return;
  }

  // If source exists, send height to parent source
  $timeout(() => {
    if (_source && _origin && _origin !== null && _origin !== undefined) {
      const heightStr = height.toString();
      const message = {
        iframeHeight: heightStr,
      };
      const jsonString = JSON.stringify(message);
      _source.postMessage(jsonString, _origin);
      _lastHeightSent = height;
    }
  }, 10);
};

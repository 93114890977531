import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Cta from 'components/Cta';
import Heading from 'modules/Estimator/components/Heading';
import { getQueryParams } from 'utils/history';
import { useStore } from '../../../../../../stores';
import {
  formatNumber,
  formatNumberWithCurrency,
} from '../../../../../../utils/numbers';
import { firePriceDetailsCtaClick } from 'utils/Analytics/estimatorAnalytics';
import { onEnterPress } from 'utils/onEnterPress';
import PositiveEquityNotification from './PositiveEquityNotification';
import GlobalStore from 'stores/GlobalStore';
import styles from './PaymentDetails.module.scss';

const PaymentDetailsDesktop: React.FC = () => {
  const { t } = useTranslation();
  const {
    estimatorStore: {
      getVehicleDetails,
      payOptForm,
      selectedTab,
      selectedVehicleDetails,
      hasVin,
    },
    modalStore: { openDisclaimerModal, openPriceDetailsModal },
  } = useStore();

  const isBuyTab = selectedTab === 'buy';
  const thirdPaymentCell = isBuyTab ? t('down_payment') : t('due_at_signing');
  const {
    dueAtSigning,
    payment,
    mileage,
    terms,
    rate,
    downPaymentSum,
    markup,
  } = payOptForm;

  const [isDAP, setIsDAP] = useState(false);
  const [isTaab, setIsTaab] = useState(false);

  const formatedThirdPaymentValue = isBuyTab
    ? formatNumberWithCurrency(downPaymentSum)
    : formatNumberWithCurrency(dueAtSigning);

  useEffect(() => {
    const { vin, isDap, isTaab } = getQueryParams();

    setIsDAP(isDap === 'true' ?? false);
    setIsTaab(isTaab === 'true' ?? false);

    if (Object.keys(selectedVehicleDetails).length === 0 && !!vin) {
      getVehicleDetails(vin as string);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={styles.payment}>
      <Heading text={` ${t(selectedTab)} ${t('payment_summary')}`} small />
      <div className={styles.firstPaymentDetailsWrapper}>
        {/*Monthly payment*/}
        <div>
          ${typeof payment === 'number' ? String(payment) : '-'}
          <span className={styles.label}>/{t('month_abbreviation')}</span>
        </div>
        {/* Annual mileage(lease) or APR(finance) */}
        <div>
          {isBuyTab
            ? `${formatNumber((rate ?? 0) + (markup ?? 0))}%`
            : formatNumber(mileage)}
          <span className={styles.label}>
            {isBuyTab ? 'APR' : t('annual_mileage').toLowerCase()}
            <sup
              onClick={() => openDisclaimerModal(isBuyTab ? '4' : '6')}
              onKeyPress={e =>
                onEnterPress(e, () => openDisclaimerModal(isBuyTab ? '4' : '6'))
              }
              tabIndex={0}
            >
              {isBuyTab ? 4 : 6}
            </sup>
          </span>
        </div>
        {/* Term months */}
        <div>
          {terms}
          <span className={styles.label}>
            {t('months')}
            <sup
              onClick={() => openDisclaimerModal('3')}
              onKeyPress={e => onEnterPress(e, () => openDisclaimerModal('3'))}
              tabIndex={0}
            >
              3
            </sup>
          </span>
        </div>
      </div>
      {/* Due at signing (lease) or down payment (finance) */}
      <div className={styles.secondPaymentDetailsWrapper}>
        <div>
          {formatedThirdPaymentValue !== '' ? formatedThirdPaymentValue : '-'}
          <span className={styles.sub}>
            {' '}
            {thirdPaymentCell}
            {isBuyTab && (
              <sup
                onClick={() => openDisclaimerModal('2')}
                onKeyPress={e =>
                  onEnterPress(e, () => openDisclaimerModal('2'))
                }
                tabIndex={0}
              >
                2
              </sup>
            )}
          </span>
        </div>
      </div>
      <div className={styles.disclaimer}>{t('payment_disclaimer')}</div>
      {/* DAP or TSRP */}
      <div className={cx(styles.paymentElements, styles.priceWrapper)}>
        <p>
          {isTaab ? t('taab') : isDAP ? t('dap') : t('msrp')}
          <sup
            onClick={() =>
              openDisclaimerModal(isTaab ? '11' : isDAP ? '9' : '7')
            }
            onKeyPress={e =>
              onEnterPress(e, () =>
                openDisclaimerModal(isTaab ? '11' : isDAP ? '9' : '7')
              )
            }
            tabIndex={0}
          >
            {isTaab ? 11 : isDAP ? 9 : 7}
          </sup>
        </p>
        <span>${formatNumber(payOptForm.baseMsrp)}</span>
      </div>
      <div className={styles.disclaimer}>{t('price_disclaimer')}</div>
      <div className={styles.paymentDetails}>
        <div className={styles.paymentElements}>
          <p>
            {t('trade_in')}
            <sup
              onClick={() => openDisclaimerModal('5')}
              onKeyPress={e => onEnterPress(e, () => openDisclaimerModal('5'))}
              tabIndex={0}
            >
              5
            </sup>
          </p>
          <span>-${formatNumber(payOptForm.tradeIn)}</span>
        </div>
        {hasVin && (
          <>
            <div className={styles.paymentElements}>
              <p>{t('delivery_and_fees')}</p>
              <span>
                {formatNumberWithCurrency(selectedVehicleDetails.price?.dph)}
              </span>
            </div>
          </>
        )}
      </div>
      {hasVin && (
        <div className={styles.ctaWrapper}>
          <Cta
            onClick={() => {
              openPriceDetailsModal();
              firePriceDetailsCtaClick();
            }}
            variant="withRedUnderline"
          >
            View Price Details
          </Cta>
        </div>
      )}
      {GlobalStore.showPositiveEquityMessage && (
        <div className={styles.notificationWrapper}>
          <PositiveEquityNotification />
        </div>
      )}
    </div>
  );
};

export default observer(PaymentDetailsDesktop);

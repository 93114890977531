import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Header from '../../components/Header';
import DevTools from '../../components/DevTools';

import styles from './VehicleSelect.module.scss';

import { useStore } from '../../stores';
import { createLocation } from '../../utils/navigation';
import { PAGE_TITLE_VEHICLE_SELECT } from '../../utils/Analytics/index';
import { useAnalyticsAAPageLoad } from '../../hooks/useAnalyticsAAPageLoad';
import { onEnterPress } from 'utils/onEnterPress';
import DisclaimerModal from 'modules/Estimator/components/DisclaimerModal';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});
const formatNumber = (baseMSRP: number) => currencyFormatter.format(baseMSRP);

const VehicleSelect: React.FC = () => {
  const { t } = useTranslation();
  const {
    store,
    store: { firstPageLoaded, setFirstPageLoaded },
    zipCodeStore: {
      vehicleSelectorPageBoostrap,
      categories,
      vehicleSelectPageImageOnload,
    },
    modalStore: { openDisclaimerModal },
  } = useStore();

  // get data if landing directly on this page
  useEffect(() => {
    if (!firstPageLoaded) {
      vehicleSelectorPageBoostrap();
      setFirstPageLoaded();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    categories.forEach(category => {
      category.series = category.series.sort(
        (a, b) => a.augmentedMsrp - b.augmentedMsrp
      );
    });
  }, [categories]);

  const ref = useAnalyticsAAPageLoad(PAGE_TITLE_VEHICLE_SELECT, true);

  return (
    <div className={styles.vsBody} ref={ref}>
      <DevTools />
      <Header subHeader="vehicle_select" />
      <main className={styles.vsContainer}>
        {categories.map(category => {
          return (
            <div className={styles.categorySection} key={category.code}>
              <h3 className={styles.categoryTitle}>
                {t(`vehicle_categories.${category.code}`)}
              </h3>
              <ul className={styles.vehicleCardList}>
                {category.series.map(series => {
                  return (
                    <li
                      className={styles.vehicleCardListElement}
                      key={series.seriesName}
                    >
                      <Link
                        to={createLocation('estimator', {
                          series: series.series,
                          year: series.year,
                        })}
                        className={styles.vehicleCard}
                        onClick={(
                          event: React.MouseEvent<HTMLAnchorElement>
                        ) => {
                          store.selectVehicle(
                            series,
                            (event.target as HTMLAnchorElement).baseURI
                          );
                        }}
                      >
                        <div className={styles.carJelly}>
                          <div>
                            <img
                              alt={series.seriesName}
                              className={styles.carJellyImg}
                              src={series.carJellyImage}
                              onLoad={vehicleSelectPageImageOnload}
                              onError={event => {
                                event.currentTarget.src =
                                  'images/blank_car_PE.png';
                              }}
                            />
                          </div>
                        </div>
                        <div className={styles.cardInfo}>
                          <div className={styles.seriesName}>
                            {series.seriesName}
                          </div>
                          <div className={styles.msrp}>
                            {formatNumber(series.augmentedMsrp || 0)}{' '}
                            <span> {t('msrp')}</span>
                            <sup
                              className={styles.sup}
                              onClick={e => {
                                openDisclaimerModal('1');
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                              onKeyPress={e =>
                                onEnterPress(e, () => openDisclaimerModal('1'))
                              }
                              tabIndex={0}
                              role="button"
                            >
                              1
                            </sup>
                          </div>
                        </div>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </main>
      <DisclaimerModal />
    </div>
  );
};

export default observer(VehicleSelect);

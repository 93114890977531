import cx from 'classnames';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { useStore } from '../../../../stores';
import { OfferForView } from '../../../../stores/EstimatorPage/Factories/offerFactory';
import './slickerOverride.css';
import OfferDetailsModal from '../OfferDetailsModal';
import ContactDealerModal from '../ContactDealer';
import { Tab } from '../../../../models/VIEW/ViewOffersTab';
import Offer from './Offer';
import ModalBase from 'components/ModalBase';
import styles from './Offers.module.scss';

type Props = {
  selectedTab: Tab;
};
const Offers: React.FC<Props> = ({ selectedTab }) => {
  const {
    estimatorStore: {
      payOptForm: { offers },
    },
    modalStore: { offerDetailId, displayContactDealerModal },
  } = useStore();
  const numberOfOffers = offers?.length ?? 0;

  const { t } = useTranslation();

  return (
    <div className={cx(styles.root, { [styles.noOffers]: !numberOfOffers })}>
      {numberOfOffers > 0 && (
        <>
          <div className={styles.title}>{t('offer_title')}</div>
          <div className={styles.listWrapper}>
            <ul className={styles.list}>
              {offers?.map((offer: OfferForView) => (
                <li key={offer.id}>
                  <Offer offer={offer} />
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
      {offers?.map((offer: OfferForView) =>
        offer.id === offerDetailId ? (
          <OfferDetailsModal offer={offer} key={offer.id} />
        ) : null
      )}

      <ModalBase
        className={styles.customDialog}
        isOpen={displayContactDealerModal}
      >
        <ContactDealerModal />
      </ModalBase>
    </div>
  );
};

export default observer(Offers);

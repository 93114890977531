import React, { useEffect } from 'react';
import cx from 'classnames';
import { observer } from 'mobx-react-lite';

import { useStore } from '../../../../stores';
import ModalBase from 'components/ModalBase';
import { sendHeight } from 'services/PostMessageService';
import handlerStore from '../../../../stores/EstimatorPage/estimatorPageHandlers';
import styles from './styles.module.scss';

const KbbTradeInModal: React.FC = () => {
  const {
    modalStore: { displayKbbTradeInModal, closeKbbTradeInModal },
  } = useStore();

  let kbb = 0;

  const messageHandler = (event: any) => {
    if (!event || !event.data) {
      return;
    }
    try {
      const kbbData = event.data;

      if (
        kbbData &&
        typeof kbbData === 'string' &&
        kbbData.includes('valueYourTrade')
      ) {
        // Get trade in price and recalculate estimate
        kbb = Number(JSON.parse(kbbData).eventFields.price);
      } else if (
        kbbData &&
        typeof kbbData === 'string' &&
        kbbData.toLowerCase().includes('button press') &&
        kbbData.toLowerCase().includes('trade-in')
      ) {
        handlerStore.onTradeInChange(kbb);
        closeKbbTradeInModal();
      } else if (
        event.data !== undefined &&
        !event.data.type &&
        typeof event.data === 'string'
      ) {
        const data = event.data?.split(':');

        if (data[0] === 'ResizeIFrame') {
          (document.getElementById(data[1]) as HTMLInputElement).height =
            data[2];

          // Determine if we've scrolled past the iframe. If so, bring it back into view.
          const iframeTop = document.getElementById(data[1])?.offsetTop; // space between top of browser window and top of iframe.

          const iframeHeight = data[2]; // height of iframe.
          const scrolledOffset = window.pageYOffset; //how far down the page have we scrolled.

          // Minus 150 pixels to compensate for the "footer" inside the iframe (i.e. "This service is provided by Kelley Blue Book...").
          const totalIframe = Number(iframeTop) + Number(iframeHeight) - 150;

          if (scrolledOffset > totalIframe) {
            document.getElementById(data[1])?.scrollIntoView();
          }

          sendHeight();
        } else if (data[0] === 'ScrollToIFrame') {
          const element = document.getElementById(data[1]);
          if (element) {
            if (window.pageYOffset > element.getBoundingClientRect().top) {
              element?.scrollIntoView();
            }
          }
        } else if (data[0] === 'SendIframeIds') {
          const frames: any = document.getElementsByTagName('iframe');
          if (frames != null && frames !== undefined && frames.length > 0) {
            for (let i = 0; i < frames.length; i++) {
              if (
                frames[i].src.toLowerCase().indexOf('ldti') > 0 ||
                frames[i].src.toLowerCase().indexOf('leaddriver') > 0 ||
                frames[i].src.toLowerCase().indexOf('profileid=') > 0
              ) {
                if (frames[i].id.length === 0) {
                  frames[i].id = 'ldtiAutoResize' + i;
                }
                frames[i]?.contentWindow?.postMessage(
                  'SendIFrameId:' + frames[i]?.id,
                  'https://ldti.syndication.kbb.com'
                );
              }
            }
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    window.addEventListener('message', messageHandler, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalBase isOpen={displayKbbTradeInModal} className={styles.customDialog}>
      <div className={cx(styles.modal)}>
        <div className={cx(styles.modalContent, 'modal-content')}>
          <div className={styles.modalHeader}>
            <div className={styles.closeModal}>
              <button
                className={styles.closeButton}
                onClick={closeKbbTradeInModal}
              >
                &#215;
              </button>
            </div>
          </div>
          <iframe
            height={600}
            id="kbbsitedriver"
            title="kbbsitedriver"
            width="100%"
            src={`https://ldti.syndication.kbb.com/?ProfileID=${process.env.REACT_APP_KBB_PROFILE_ID}`}
          />
        </div>
      </div>
    </ModalBase>
  );
};

export default observer(KbbTradeInModal);

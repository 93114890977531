import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { fireAAPageLoad, getToolBlock } from 'utils/Analytics';
import {
  extendWithGlobalBlock,
  extendWithPEBlock,
  fireInfoCtaClick,
} from 'utils/Analytics/estimatorAnalytics';
import ModalBase, {
  ModalBaseProps,
} from '../../../../../../components/ModalBase';
import styles from './style.module.scss';

interface FinanceTypeModalProps extends ModalBaseProps {}

const FinanceTypeModal = React.forwardRef<
  HTMLDivElement,
  FinanceTypeModalProps
>(({ isOpen, onClose }, ref) => {
  const { t } = useTranslation();
  const quizUrl =
    'https://www.toyotafinancial.com/content/toyotafinancial/us/en/financing_options/buy_or_lease.html';

  useEffect(() => {
    if (isOpen) {
      let block = getToolBlock();
      extendWithGlobalBlock(block, 'pe module');
      extendWithPEBlock(block);
      fireAAPageLoad(block);
    }
  }, [isOpen]);

  return (
    <ModalBase
      isOpen={isOpen}
      onClose={() => {
        if (onClose !== undefined) onClose();
      }}
      className={styles.modal}
      ref={ref}
    >
      <button
        aria-label="close"
        className={styles.closeButton}
        onClick={onClose}
      >
        &#215;
      </button>
      <div className={styles.modalWrapper} tabIndex={1}>
        <div className={styles.title}>{t('lease')}</div>
        <div className={styles.description}>
          {t('finance_type_disclaimer.lease_disclaimer')}
        </div>
        <div className={styles.title}>{t('buy')}</div>
        <div className={styles.description}>
          {t('finance_type_disclaimer.buy_disclaimer')}
        </div>
        <button
          className={styles.quizWrapper}
          onClick={e => {
            window.open(quizUrl, '', 'noopener');
            fireInfoCtaClick(e);
          }}
        >
          {t('finance_type_disclaimer.quiz')}
        </button>
      </div>
    </ModalBase>
  );
});

export default FinanceTypeModal;

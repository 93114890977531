import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from 'stores';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

import {
  createDownpaymentDisclaimer,
  createDueAtSigningDisclaimer,
} from 'modules/Estimator/utils';

const PaymentDisclaimer = () => {
  const { t } = useTranslation();
  const {
    estimatorStore: { selectedTab, payOptForm },
    zipCodeStore: { regionCode },
  } = useStore();

  const { offers, selectedOffers, securityDepositWaiver } = payOptForm;

  const isBuyTab = selectedTab === 'buy';

  const firstDisclaimer = t('payment_disclaimer')

  const secondDisclaimer = isBuyTab
    ? createDownpaymentDisclaimer(offers)
    : createDueAtSigningDisclaimer(
        securityDepositWaiver,
        selectedOffers,
        regionCode
      );

  const thirdDisclaimer =
    selectedTab === 'buy'
      ? t('finance_terms.description')
      : t('lease_terms.description');

  return (
    <div className={styles.disclaimerWrapper}>
      <div>{firstDisclaimer}</div>
      {isBuyTab && <div>{t('estimated_apr_tooltip')}</div>}
      <div>{secondDisclaimer}</div>
      <div>{thirdDisclaimer}</div>
    </div>
  );
};

export default observer(PaymentDisclaimer);

import cx from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useStore } from 'stores';
import parse, { Element } from 'html-react-parser';

import { getMarketingNameHTML } from 'utils/vehicleDetails';
import { isAcqFeeInDueAtSigning } from 'modules/Estimator/utils';
import { OfferType } from 'stores/EstimatorPage/Factories/offerFactory';
import { formatNumberWithCurrency } from 'utils/numbers';
import { onEnterPress } from 'utils/onEnterPress';
import styles from './style.module.scss';

interface Props {
  openDisclaimersModal: (id: string) => void;
}

const PriceDetailsList = ({ openDisclaimersModal }: Props) => {
  const [isVisible, setIsVisible] = useState<boolean>(true);

  const {
    zipCodeStore: { regionCode },
    estimatorStore: {
      payOptForm,
      selectedTab,
      selectedVehicleDetails,
      vehicleAccessoryDisclaimers,
    },
  } = useStore();

  const isBuyTab = selectedTab === 'buy';

  // get selected lease offer
  const selectedLeaseOffer = payOptForm.selectedOffers.filter(
    offer => offer.offerType === OfferType.LEASE
  );

  const isAcqFeeIncludedInDueAtSigning = isAcqFeeInDueAtSigning(
    selectedLeaseOffer,
    regionCode
  );

  const getMarketingNameWithDisclaimers = (marketingName: string) => {
    marketingName = getMarketingNameHTML(
      marketingName,
      vehicleAccessoryDisclaimers
    );

    return parse(marketingName, {
      replace: domNode => {
        const disclaimerId = (domNode as Element).attribs?.['disclaimerid'];
        if (disclaimerId) {
          return (
            <sup onClick={() => openDisclaimersModal(disclaimerId)}>
              {disclaimerId}
            </sup>
          );
        }
      },
    });
  };

  return (
    <div className={styles.root}>
      <div>
        <div className={cx(styles.title, styles.bold)}>
          <p>Cost of Vehicle</p>
          <div className={styles.divider} />
        </div>
        {/* Items */}
        <div className={styles.items}>
          <div className={styles.item}>
            <div>
              Base MSRP
              <sup
                onClick={() => openDisclaimersModal('10')}
                onKeyPress={e => {
                  onEnterPress(e, () => {
                    openDisclaimersModal('10');
                  });
                }}
                tabIndex={0}
              >
                10
              </sup>
            </div>
            <div>
              {formatNumberWithCurrency(selectedVehicleDetails.price?.baseMsrp)}
            </div>
          </div>
          <div className={styles.item}>
            <div>Installed Packages & Accessories</div>
            <div>
              {formatNumberWithCurrency(
                selectedVehicleDetails.price?.optTotalMsrp
              )}
            </div>
            <span
              className={cx([
                styles.carret,
                styles.carretDown,
                { [styles.carretUp]: isVisible },
              ])}
              onClick={() => setIsVisible(!isVisible)}
            />
          </div>
          {/* Sublist for installed packages & accessories */}
          {isVisible && (
            <div className={styles.sublist}>
              {selectedVehicleDetails.options &&
                selectedVehicleDetails.options.map(option => (
                  <div className={styles.subitem} key={option.optionCd}>
                    <div>
                      {getMarketingNameWithDisclaimers(option.marketingName)}
                    </div>
                    <div>{formatNumberWithCurrency(option.msrp)}</div>
                  </div>
                ))}
            </div>
          )}
          <div className={styles.item}>
            <div>
              Delivery Processing and Handling Fee
              <sup
                onClick={() => openDisclaimersModal('7')}
                onKeyPress={e => {
                  onEnterPress(e, () => {
                    openDisclaimersModal('7');
                  });
                }}
                tabIndex={0}
              >
                7
              </sup>
            </div>
            <div>
              {formatNumberWithCurrency(selectedVehicleDetails.price?.dph)}
            </div>
          </div>
          <div className={styles.item}>
            <div>
              Total SRP
              <sup
                onClick={() => openDisclaimersModal('7')}
                onKeyPress={e => {
                  onEnterPress(e, () => {
                    openDisclaimersModal('7');
                  });
                }}
                tabIndex={0}
              >
                7
              </sup>
            </div>
            <div>
              {formatNumberWithCurrency(
                selectedVehicleDetails.price?.totalMsrp
              )}
            </div>
          </div>
          {isBuyTab && (
            <>
              <div className={styles.divider} />
              <div className={styles.item}>
                <div>
                  Net Trade-In Value
                  <sup
                    onClick={() => openDisclaimersModal('5')}
                    onKeyPress={e => {
                      onEnterPress(e, () => {
                        openDisclaimersModal('5');
                      });
                    }}
                    tabIndex={0}
                  >
                    5
                  </sup>
                </div>
                <div>
                  {payOptForm.tradeIn !== 0 && `-`}
                  {formatNumberWithCurrency(payOptForm.tradeIn)}
                </div>
              </div>
              <div className={styles.item}>
                <div>
                  Cash Down Payment
                  <sup
                    onClick={() => openDisclaimersModal('2')}
                    onKeyPress={e => {
                      onEnterPress(e, () => {
                        openDisclaimersModal('2');
                      });
                    }}
                    tabIndex={0}
                  >
                    2
                  </sup>
                </div>
                <div>
                  {payOptForm.downPayment !== 0 && `-`}
                  {formatNumberWithCurrency(payOptForm.downPayment)}
                </div>
              </div>
              <div className={styles.divider} />
              <div className={cx(styles.item, styles.bold)}>
                <div>Total Amount Financed</div>
                <div>
                  {formatNumberWithCurrency(
                    selectedVehicleDetails.price?.totalMsrp -
                      payOptForm.tradeIn -
                      (payOptForm.downPaymentSum ?? 0)
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div>
        {!isBuyTab && (
          <>
            <div className={cx(styles.title, styles.bold)}>
              <p>What’s required at Signing</p>
              <div className={styles.divider} />
            </div>
            <div className={styles.items}>
              <div className={styles.item}>
                <div>First Month’s Payment</div>
                <div>{formatNumberWithCurrency(payOptForm?.payment)}</div>
              </div>
              <div className={styles.item}>
                <div>Security Deposit Due</div>
                <div>
                  {formatNumberWithCurrency(payOptForm?.securityDeposit)}
                </div>
              </div>
              {isAcqFeeIncludedInDueAtSigning && (
                <div className={styles.item}>
                  <div>Acquistion Fee</div>
                  <div>
                    {formatNumberWithCurrency(payOptForm?.acquisitionFee)}
                  </div>
                </div>
              )}
              <div className={styles.item}>
                <div>
                  Down Payment
                  <sup
                    onClick={() => openDisclaimersModal('8')}
                    onKeyPress={e => {
                      onEnterPress(e, () => {
                        openDisclaimersModal('8');
                      });
                    }}
                    tabIndex={0}
                  >
                    8
                  </sup>
                </div>
                <div>{formatNumberWithCurrency(payOptForm?.downPayment)}</div>
              </div>
              <div className={styles.divider} />
              <div className={styles.item}>
                <div>
                  Net Trade-In Value
                  <sup
                    onClick={() => openDisclaimersModal('5')}
                    onKeyPress={e => {
                      onEnterPress(e, () => {
                        openDisclaimersModal('5');
                      });
                    }}
                    tabIndex={0}
                  >
                    5
                  </sup>
                </div>
                <div>
                  {payOptForm.tradeIn !== 0 && `-`}
                  {formatNumberWithCurrency(payOptForm.tradeIn)}
                </div>
              </div>
              <div className={styles.divider} />
              <div className={cx(styles.item, styles.bold)}>
                <div>Amount Due at Lease Signing</div>
                <div>{formatNumberWithCurrency(payOptForm.dueAtSigning)}</div>
              </div>
              <div className={styles.disclaimerWrapper}>
                Everything required when signing including cash, rebates, and
                trade-in
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default observer(PriceDetailsList);

import React from 'react';
import { useTranslation } from 'react-i18next';

import { OfferForView } from '../../../../../../stores/EstimatorPage/Factories/offerFactory';
import styles from './titles.module.scss';

type OfferDetailsProps = {
  offer: OfferForView;
};

export const AprTitle: React.FC<OfferDetailsProps> = ({ offer }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.offerInfo}>
        <span>
          {offer.offerLabel.split('|')[0].substring(0, 3)}
          <sup>%</sup>
          <label>apr</label>
        </span>
      </div>
      <div className={styles.offerInfo}>
        <span>
          {offer.offerLabel.split('|')[1].substring(0, 3)}
          <label>{t('offer_details_modal.months')}</label>
        </span>
      </div>
    </>
  );
};
export const LeaseTitle: React.FC<OfferDetailsProps> = ({ offer }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.offerInfoLease}>
        <span>
          {offer.offerLabel
            .split('|')[0]
            .substring(0, offer.offerLabel.indexOf('|'))}
          <label>{t('offer_details_modal.per_month')}</label>
        </span>
      </div>
      <div className={styles.offerInfoLease}>
        <span>
          {offer.offerLabel.split('|')[1].substring(0, 3)}
          <label>{t('offer_details_modal.months')}</label>
        </span>
      </div>
      <div className={styles.offerInfoLease}>
        <span>{`$${offer.dueAtSigning}`}</span>
        <label>{t('due_at_signing')}</label>
      </div>
    </>
  );
};

export const CustomerCashTitle: React.FC<OfferDetailsProps> = ({ offer }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.offerInfoFullWidth}>
      <p className={styles.cashBack}>
        {`$${offer.amount}`}
        <span>{t('cash_back')}</span>
      </p>
    </div>
  );
};

export const DefaultTitle: React.FC<OfferDetailsProps> = ({ offer }) => (
  <div className={styles.offerInfoFullWidth}>
    <p className={styles.cashBack}>{`$${offer.amount}`}</p>
  </div>
);

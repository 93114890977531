import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import ModalClose from '../../../../components/Modal/ModalClose';
import Input from './Components/Input';
import Cta from '../../../../components/Cta';

import { Dealer } from '../../../../models/VIEW/RequestAQuote';
import { OfferForView } from '../../../../stores/EstimatorPage/Factories/offerFactory';
import { stripString } from '../../../../utils/stripString';

import * as contactDealerAnalytics from '../../../../utils/Analytics/contactDealerAnalytics';
import DealerForm from './Components/DealerForm';

import styles from './ContactDealer.module.scss';

type Props = {
  onSubmit: any;
  loadMore: any;
  handleInteraction: any;
  handleDealerZipChange: any;
  setSelectedDealer: any;
  handleEditZipCodeOpen: any;
  title: string;
  gradeTitle: string;
  currentZip: string;
  staticCommentTextArea: React.RefObject<HTMLTextAreaElement>;
  staticComment: string;
  limit: number;
  dealers: Dealer[];
  selectedDealer?: Dealer;
  zipRef: React.RefObject<HTMLInputElement>;
  editZipCode: boolean;
  selectedOffers: OfferForView[];
  disclaimerLink: string;
  formik: any;
};
const ContactDealerInner: React.FC<Props> = ({
  title,
  formik,
  onSubmit,
  gradeTitle,
  handleInteraction,
  currentZip,
  staticCommentTextArea,
  staticComment,
  loadMore,
  limit,
  dealers,
  selectedDealer,
  setSelectedDealer,
  editZipCode,
  zipRef,
  handleEditZipCodeOpen,
  handleDealerZipChange,
  selectedOffers,
  disclaimerLink,
}) => {
  const { t } = useTranslation();
  return (
    <form onSubmit={formik.handleSubmit}>
      <ModalClose />
      <div className={styles.modalBody}>
        <h1 className={styles.title}>{t('contact_dealer')}</h1>
        <label htmlFor="vehicle" className={styles.label}>
          {t('contact_dealer_modal.vehicle')}
        </label>
        <Input
          disabled
          className={styles.input}
          value={`${title} ${stripString(gradeTitle)}`}
        />
        <label htmlFor="contact" className={styles.label}>
          {t('contact_dealer_modal.your_contact_info')}
        </label>
        <Input
          id="firstName"
          error={formik.touched.firstname && formik.errors.firstname}
          name="firstname"
          {...formik.getFieldProps('firstname')}
          placeholder={t('contact_dealer_modal.first_name_placeholder')}
          onClick={() => handleInteraction('firstName')}
          aria-required="true"
        />
        <Input
          id="lastName"
          error={formik.touched.lastname && formik.errors.lastname}
          name="lastname"
          {...formik.getFieldProps('lastname')}
          placeholder={t('contact_dealer_modal.second_name_placeholder')}
          onClick={() => handleInteraction('lastName')}
          aria-required="true"
        />
        <label
          htmlFor="preference"
          className={cx(styles.label, styles.contactMeByLabel)}
        >
          {t('contact_dealer_modal.contact_me_by')}
        </label>
        <span className={styles.preferenceContainer}>
          <span>
            <input
              type="radio"
              name="preference"
              value="email"
              id="email"
              className={styles.radio}
              onChange={formik.handleChange}
              checked={formik.values.preference === 'email'}
              aria-checked={formik.values.preference === 'email'}
            />
            <label
              htmlFor="email"
              className={styles.contactOption}
              tabIndex={0}
            >
              {t('contact_dealer_modal.email')}
            </label>
          </span>
          <span>
            <input
              type="radio"
              name="preference"
              value="phone"
              id="phone"
              className={styles.radio}
              onChange={formik.handleChange}
              checked={formik.values.preference === 'phone'}
              aria-checked={formik.values.preference === 'phone'}
            />
            <label
              htmlFor="phone"
              className={styles.contactOption}
              tabIndex={0}
            >
              {t('contact_dealer_modal.phone')}
            </label>
          </span>
        </span>
        <Input
          type="email"
          name="email"
          {...formik.getFieldProps('email')}
          error={formik.touched.email && formik.errors.email}
          placeholder={
            formik.values.preference === 'email'
              ? t('contact_dealer_modal.email_placeholder_required')
              : t('contact_dealer_modal.email_placeholder')
          }
          onClick={() => handleInteraction('email')}
          aria-required={
            formik.values.preference === 'email' ? 'true' : 'false'
          }
        />
        <Input
          type="tel"
          name="phone"
          {...formik.getFieldProps('phone')}
          error={formik.touched.phone && formik.errors.phone}
          placeholder={
            formik.values.preference === 'phone'
              ? t('contact_dealer_modal.phone_placeholder_required')
              : t('contact_dealer_modal.phone_placeholder')
          }
          onClick={() => handleInteraction('phone')}
          aria-required={
            formik.values.preference === 'phone' ? 'true' : 'false'
          }
        />
        <div className={styles.commentsWrapper}>
          <label>{t('contact_dealer_modal.leave_a_comment')}</label>
          <textarea
            className={styles.staticComment}
            name="staticComment"
            ref={staticCommentTextArea}
            disabled
            value={staticComment}
            placeholder="Comment"
          />

          <textarea
            className={styles.comment}
            name="inputComment"
            maxLength={250}
            placeholder={t('contact_dealer_modal.enter_comment')}
            onClick={() => handleInteraction('Enter Comment...')}
            value={formik.values.comment}
            {...formik.getFieldProps('comment')}
          />
        </div>
        <DealerForm
          editZipCode={editZipCode}
          currentZip={currentZip}
          zipRef={zipRef}
          handleDealerZipChange={handleDealerZipChange}
          handleEditZipCodeOpen={handleEditZipCodeOpen}
          dealers={dealers}
          setSelectedDealer={setSelectedDealer}
          loadMore={loadMore}
          limit={limit}
          selectedDealer={selectedDealer}
        />
        <div className={styles.disclaimer}>
          <span>{t('contact_modal_disclaimer.text')}</span>
          <a
            className={styles.disclaimerLink}
            rel="noopener noreferrer"
            target="_blank"
            href={disclaimerLink}
            onClick={contactDealerAnalytics.onDisclaimerLinkClink(
              selectedOffers
            )}
          >
            {t('contact_modal_disclaimer.click_here')}
          </a>
        </div>
        <div className={styles.quoteCta}>
          <Cta
            type="submit"
            variant="redBackground"
            disabled={!(formik.isValid && formik.dirty)}
          >
            {t('contact_dealer_modal.quoteCta')}
          </Cta>
        </div>
      </div>
    </form>
  );
};

export default ContactDealerInner;
